import React from "react";

const Privacy = () => {
  return (
    <div>
      <h1>计数助理隐私声明</h1>
      本指引是计数助理开发者（以下简称“开发者”）为处理你的个人信息而制定。
      <h2>开发者处理的信息</h2>
      根据法律规定，开发者仅处理实现 计数助理功能所必要的信息。
      <h2>你的权益</h2>
      开发者承诺，除法律法规另有规定外，开发者对你的信息的保存期限应当为实现处理目的所必要的最短时间。
      关于你的个人信息，你可以通过以下方式与开发者联系，行使查阅、复制、更正、删除等法定权利。
      邮箱： tupulin@163.com
      <h2>开发者对信息的存储</h2>
      开发者承诺，除法律法规另有规定外，开发者对你的信息的保存期限应当为实现处理目的所必要的最短时间。
      <h2>信息的使用规则</h2>
      开发者将会在本指引所明示的用途内使用收集的信息
      如开发者使用你的信息超出本指引目的或合理范围，开发者必须在变更使用目的或范围前，再次以应用弹窗、邮件等方式告知并征得你的明示同意。
      <h2>信息对外提供</h2>
      开发者承诺，不会主动共享或转让你的信息至任何第三方，如存在确需共享或转让时，开发者应当直接征得或确认第三方征得你的单独同意。
      开发者承诺，不会对外公开披露你的信息，如必须公开披露时，开发者应当向你告知公开披露的目的、披露信息的类型及可能涉及的信息，并征得你的单独同意。
      你认为开发者未遵守上述约定，或有其他的投诉建议、或未成年人个人信息保护相关问题，可通过以下方式与开发者联系。
      邮箱： tupulin@163.com
      <h2>声明如何更新</h2>
      <p>
        开发者保留不时更新声明的权利。如果声明变更，新版本会被发布在官方网站中
        <a
          href="https://www.tupulin.com/counter-assistant-privacy"
          target="_blank"
        >
          https://www.tupulin.com/counter-assistant-privacy
        </a>
      </p>
      更新日期：2022-11-17 生效日期：2022-11-17
    </div>
  );
};

export default Privacy;
